import { AlertCircle, Mail, Search } from 'lucide-react';
import React, { ChangeEvent, useId, useState } from 'react';
import { cx } from '../../helpers/utils';

export const inputWrapperClasses =
  'rounded-md shadow-sm flex flex-row ring-1 ring-inset p-[2px] focus-within:ring-2 focus-within:ring-brand-600 ring-slate-300';

export const inputWrapperClassesNaked = 'rounded-md flex flex-row';

export const inputBaseClasses =
  '[&::-webkit-search-cancel-button]:hidden block w-full rounded-md border-0 p-0 text-slate-900 placeholder:text-slate-400 focus-visible:ring-0 focus-visible:outline-none focus-visible:ring-transparent py-1 px-3 text-sm md:text-base';

export const inputBaseClassesNaked =
  '[&::-webkit-search-cancel-button]:hidden border-transparent block w-full placeholder:text-slate-400 tracking-normal outline-none focus:outline-none';

export const labelBaseClasses = 'block text-sm font-semibold leading-6';

const inlineLayoutClasses = 'flex flex-row gap-3';

type InputElement = HTMLInputElement | HTMLTextAreaElement;

export const TextInput: React.FC<{
  id?: string;
  label?: string | React.ReactNode;
  placeholder?: string;
  type?:
    | 'text'
    | 'email'
    | 'password'
    | 'textarea'
    | 'url'
    | 'number'
    | 'search';
  variant?: 'naked' | 'default';
  className?: string;
  error?: boolean;
  disabled?: boolean;
  errorLabel?: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  inline?: boolean;
  value?: string | number;
  required?: boolean;
  onKeyDown?: React.KeyboardEventHandler<InputElement>;
  onBlur?: (value: string) => void;
  onChange?: (value: string) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  textAreaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  autoFocus?: boolean;
}> = ({
  id,
  disabled,
  label,
  placeholder,
  type = 'text',
  error = false,
  errorLabel,
  helperText,
  inline = false,
  value,
  required = false,
  onKeyDown,
  onBlur,
  onChange,
  inputProps = {},
  textAreaProps = {},
  autoFocus,
  className,
  variant = 'default',
}) => {
  const [displayValue, setDisplayValue] = useState(value);
  const textFieldId = useId();

  const onTextFieldChange = (e: ChangeEvent<InputElement>) => {
    setDisplayValue(e.target.value);
    onChange?.(e.target.value);
  };
  const onTextFieldBlur = () => onBlur?.(String(displayValue));

  const defaultInput = (
    <input
      id={id}
      disabled={disabled}
      type={type}
      name={id || textFieldId}
      value={displayValue}
      onChange={onTextFieldChange}
      required={required}
      placeholder={placeholder?.toString()}
      aria-describedby={`${id || textFieldId}-description`}
      autoFocus={autoFocus}
      onBlur={onTextFieldBlur}
      onKeyDown={onKeyDown}
      {...inputProps}
      className={cx(
        'form-input',
        variant,
        variant === 'naked' ? inputBaseClassesNaked : inputBaseClasses,
        error ? 'focus:ring-red-500' : 'focus:ring-indigo-600',
        inputProps.className
      )}
    />
  );

  const textAreaInput = (
    <textarea
      id={id}
      disabled={disabled}
      rows={4}
      name={id ?? textFieldId}
      required={required}
      onChange={onTextFieldChange}
      value={displayValue}
      className={cx(
        'form-textarea',
        inputBaseClasses,
        error ? 'focus:ring-red-500' : 'focus:ring-indigo-600'
      )}
      placeholder={placeholder?.toString()}
      aria-describedby={`${textFieldId}-description`}
      {...textAreaProps}
      autoFocus={autoFocus}
      onBlur={onTextFieldBlur}
      onKeyDown={onKeyDown}
    />
  );

  return (
    <div
      className={cx(
        'flex grow flex-col',
        inline ? inlineLayoutClasses : '',
        className
      )}
    >
      <label
        htmlFor={id ?? textFieldId}
        className={cx(
          labelBaseClasses,
          inline ? 'pt-3' : '',
          error ? 'text-red-500' : 'text-slate-600'
        )}
      >
        {label}
      </label>
      <div className="flex grow flex-col">
        <div
          className={cx(
            label ? 'mt-1' : '',
            variant === 'naked'
              ? inputWrapperClassesNaked
              : inputWrapperClasses,
            type === 'textarea' ? 'flex grow flex-row' : '',
            error ? 'ring-red-600 focus-within:ring-red-600' : ''
          )}
        >
          {type === 'email' || type === 'search' ? (
            <div
              className={cx(
                'pointer-events-none flex items-center justify-center pl-1.5 group-focus:ring-blue-600',
                error ? 'text-red-600' : 'text-slate-400'
              )}
            >
              {type === 'search' ? (
                <Search size="1.25rem" aria-hidden="true" />
              ) : (
                <Mail size="1.25rem" aria-hidden="true" />
              )}
            </div>
          ) : null}
          {type === 'textarea' ? textAreaInput : defaultInput}
          {error && (
            <div className="pointer-events-none flex items-center justify-center pr-1.5 group-focus:ring-blue-600">
              <AlertCircle
                className={cx('h-5 w-5 text-red-600')}
                aria-hidden="true"
              />
            </div>
          )}
        </div>
        {helperText ||
          (errorLabel && (
            <p
              className={cx(
                'mt-1 h-5 text-sm',
                error ? 'text-red-600' : 'text-slate-500'
              )}
            >
              {error ? errorLabel : helperText}
            </p>
          ))}
      </div>
    </div>
  );
};
